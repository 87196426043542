<div class="container">
  <div class="top-container" fxLayout="column" fxLayoutAlign="space-evenly start">
      <app-profile-info *ngIf="isVisible" [isAllowRedirectToClientArea]="false" [visible]="isVisible" size="small" class="dialog"></app-profile-info>
  </div>
  <div class="deposit">
      <div fxLayoutAlign="center center" fxLayoutGap="16px" fxLayout="column" fxfill>
          <div class="balance" fxLayoutAdivgn="center center" fxLayout="row" *ngIf="balanceObs$ | async as balance">
              <div class="balance-icon"><img src="assets/icons/icon-wallet-small.svg" /></div>
              <div class="info">{{balance.balance}}</div>
              <div class="info">{{balance.currency}}</div>
          </div>
          <div class="deposit-button" fxLayout="row" fxLayoutAlign="center center" (click)="goToDeposit()">
              <div class="button-container" fxLayout="row" fxLayoutGap="25px" fxLayoutAlign="start center">
                  <div class="label"><strong i18n>deposit</strong></div>
                  <div class="add-icon" fxLayoutAlign="center center" fxLayout="row">
                      <img src="assets/icons/icon-add.svg" />
                  </div>
              </div>
          </div>

      </div>
      <div class="quit" fxLayoutAlign="center center">
        <button round-button color="yellow" (click)="quit()" i18n>quit game</button>
      </div>

      <!--div class="last-played-panel">
          <div class="category-name-container" fxLayout="row" fxLayoutAlign="start center">
              <div class="category-title" i18n>last played</div>
          </div>
          <app-front-page-games [isOutsideCategory]="true" (click)="closeDialog(false)" [data]="gameCategory"></app-front-page-games>
      </div-->
  </div>
</div>
<div>
<div class="game-info" *ngIf="currentGame">
    <!-- The code below duplicates (with slightly changes) the thumbnail code from the game-dialog.component.
    Should we create a separated component for the game-thumbnail with the tags? If we create that component,
    we don't have to copy new tags for all places. But it can affect many places in code. -->
    <div class="thumbnail">
      <img *ngIf="currentGame.thumbnailDesktopSquare else oldimage" src="{{ currentGame.thumbnailDesktopSquare?.url }}" alt="{{ currentGame.name }}" />
      <ng-template #oldimage>
        <img src="{{ currentGame.thumbnail?.url }}" alt="{{ currentGame.name }}" />
      </ng-template>

      <div *ngFor="let tag of currentGame?.tag" [ngClass]="tag === 'exclusive' ? 'exclusive' : tag === 'power_weekends' ? 'power_weekends' : 'badge'" [ngSwitch]="tag" class="tags">
        <ng-container *ngSwitchCase="'drops_win'">
          <img src="assets/icons/drops-win-icon.png">
        </ng-container>
        <ng-container *ngSwitchCase="'cash_drops'">
          <img src="assets/icons/tag-thumbnail-mobile-kalamba.png">
        </ng-container>

        <ng-container *ngSwitchCase="'take_prize'">
          <img src="assets/icons/take-the-prize-mobile.png">
        </ng-container>

        <ng-container *ngSwitchCase="'exclusive'">
          <img src="assets/icons/tag-lemon-exclusive-mobile.svg">
        </ng-container>
        <ng-container *ngSwitchCase="'holiday_spin_splash'">
          <img src="assets/icons/holiday-spin-splash-mobile-tag-2x.png">
        </ng-container>

        <ng-container *ngSwitchCase="'powe_-weekends'" class="power-weekends">
          <img src="assets/icons/tag-power-weekends-pragmatic-mobile-2x.png">
        </ng-container>


        <ng-container *ngSwitchDefault></ng-container>
      </div>
    </div>


  <div class="description">
    <div class="game-title">{{ currentGame.name }}</div>
    <div class="game-provider">{{ currentGame.provider }}</div>
    <app-game-favourites-toggle [gameId]="currentGame.gameid"></app-game-favourites-toggle>
  </div>
</div>

<div class="last-played-panel">
   <div fxLayout="row" fxLayoutAlign="space-between center" class="wrapper">
      <div class="category-name-container" fxLayout="row" fxLayoutAlign="start center">
        <img class="category-icon" src="{{licenceService.getKey('my-games-icon')}}" alt="icon-my-games"/>
        <div class="category-title" i18n>My games</div>
      </div>
     <a class="all-games-container" (click)="myGames();">
       <div i18n>All</div>
       <div><img src="{{licenceService.getKey('icon-right')}}" icon-btn size="large" alt="arrow-right"/></div>
     </a>
   </div>
    <app-front-page-games [rowsCount]="1" [isOutsideCategory]="true" (click)="closeDialog(false)" [data]="gameCategory"></app-front-page-games>
</div>
    <!-- <div class="prize-boxes-container">
    <app-prize-boxes>
    </app-prize-boxes>
</div> -->
    <app-close-dialog-button (closeEvent)="closeDialog(false)"></app-close-dialog-button>
